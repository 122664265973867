import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ReactComponent as ChartbarIcon } from '@icons/wolfkit-light/chartbar-2-light.svg';
import { ContainerColumn, ContainerRow } from '@components/styled';
import Button from '@shared/ui/buttons/Button';
import Dropdown from '@shared/ui/menus/Dropdown';
import ExchangeWalletHeadline from '@entities/wallet/ui/ExchangeWalletHeadline';
import ExchangeWalletAssetsBar from '@entities/wallet/ui/ExchangeWalletAssetsBar';
import ExchangeWalletBalance from '@entities/wallet/ui/ExchangeWalletBalance';
import ExchangePortfolios from '@entities/portfolio/ui/ExchangePortfolios';
import useAppNavigation from '@utils/hooks/useAppNavigation';
import BalanceManagementSidebar from '../BalanceManagementSidebar';
const Container = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'useEmbeddedStyles' })(props => (Object.assign({ width: 600, flexShrink: 0, height: 'auto', backgroundColor: '#fff', gap: props.theme.spacing_sizes.xm, justifyContent: 'space-between' }, !props.useEmbeddedStyles && ({
    padding: props.theme.spacing_sizes.l,
    boxShadow: '0px 4px 8px -6px rgba(24, 39, 75, 0.04)',
    borderRadius: 10,
}))));
const ButtonsContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const PrimaryButtonContainer = styled(ContainerRow)(props => ({
    flex: 0.45,
    flexShrink: 0,
    width: 'auto',
    height: 'auto',
}));
const SecondaryButtonsContainer = styled(ContainerRow)(props => ({
    flex: 0.55,
    flexShrink: 0,
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
    alignItems: 'end',
}));
const ExchangeWalletInfo = styled(ContainerRow)((props) => ({
    height: 150,
    gap: props.theme.spacing_sizes.m,
}));
const ExchangeWalletBalanceStyled = styled(ExchangeWalletBalance)(() => ({
    flex: 0.45,
}));
const ExchangePortfoliosStyled = styled(ExchangePortfolios)(() => ({
    flex: 0.55,
}));
const ExchangeWallet = ({ wallet, portfolios, disabled = false, variant = 'standalone', }) => {
    const { t } = useTranslation();
    const { navigate, routes } = useAppNavigation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    // TODO: temporary solution, in future we will get supported types from backend
    const isWalletSupported = wallet.type === 'SPOT' || wallet.type === 'MARGIN';
    const handleBalanceManagementClick = () => {
        setIsSidebarOpen(true);
    };
    const handleSidebarClose = () => {
        setIsSidebarOpen(false);
    };
    const handleViewData = () => {
        navigate(routes.OVERVIEW_WALLET_DETAILS, { params: { walletId: wallet.id } });
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Container, { useEmbeddedStyles: variant === 'embedded', children: [_jsx(ExchangeWalletHeadline, { wallet: wallet, disabled: disabled }), _jsx(ExchangeWalletAssetsBar, { wallet: wallet, disabled: disabled }), _jsxs(ExchangeWalletInfo, { children: [_jsx(ExchangeWalletBalanceStyled, { totalBalance: parseFloat(wallet.totalAssetsUsd), usdtBalance: parseFloat(wallet.totalAssetsUsdt), disabled: disabled }), _jsx(ExchangePortfoliosStyled, { exchangeId: wallet.id, portfolios: portfolios, dailyIcomeRatio: 18.26, dailyIncome: 95.29, isExchangeSupported: isWalletSupported, disabled: disabled })] }), _jsxs(ButtonsContainer, { children: [_jsx(PrimaryButtonContainer, { children: _jsx(Button, { onClick: handleBalanceManagementClick, fullWidth: true, disabled: disabled, children: t('overview.exchange_wallets.card.balance_management') }) }), _jsxs(SecondaryButtonsContainer, { children: [variant === 'standalone' && (_jsx(Button, { variant: 'tinted', startIcon: ChartbarIcon, fullWidth: true, disabled: disabled, onClick: handleViewData, children: t('overview.exchange_wallets.card.view_data') })), variant === 'embedded' && (_jsx(Button, { variant: 'tinted', startIcon: ChartbarIcon, fullWidth: true, disabled: disabled, onClick: () => { }, children: "Add Portfolio" })), _jsx(Dropdown, { buttonVariant: 'tinted', disabled: disabled, options: [
                                            { itemKey: 'option', children: 'Option 1' },
                                        ] })] })] })] }), _jsx(BalanceManagementSidebar, { isOpen: isSidebarOpen, exchangeWallet: wallet, onClose: handleSidebarClose })] }));
};
export default ExchangeWallet;
